import * as Yup from 'yup'

import { verifyEmail } from '../../../../molecules/controls/InputEmail/utils'

//https://stackoverflow.com/questions/62950190/how-to-set-dynamic-error-messages-in-yup-async-validation

let previousEmailValue = ''

const schemaRecipientDelivery = (maxChars) =>
	Yup.object()
		.shape({
			viaEmail: Yup.bool(),
			viaSms: Yup.bool(),
			recipients: Yup.array()
				.min(1)
				.of(
					Yup.object()
						.shape({
							/// EMAIL
							deliveryEmail: Yup.string()
								.nullable()
								.notRequired()
								.test('is-valid-email', 'Invalid email', function (value) {
									// console.log('Compare', value, previousEmailValue)
									return new Promise((resolve, reject) => {
										if (!value) {
											//&& !this.from[1].value.viaEmail
											resolve(true)
										} else if (
											value &&
											Yup.string().email().isValidSync(value) &&
											value !== previousEmailValue
										) {
											verifyEmail(value)
												.then((res) => {
													if (!res.isValid) {
														const message = 'Invalid email, please check your entry.'
														resolve(new Yup.ValidationError(message))
													} else {
														resolve(true)
													}
												})
												.catch((e) => {
													console.warn('Verify email Error:', e)
												})
										} else {
											resolve(false)
										}
									})
								}),
							confirmDeliveryEmail: Yup.string()
								.nullable()
								.notRequired()
								.test({
									name: 'deliveryEmail-confirmEmail-match',
									message: 'Emails must match',
									test: function (value) {
										const deliveryEmail = this.parent.deliveryEmail
										if (!deliveryEmail || value === deliveryEmail) {
											return true
										} else {
											return false
										}
									},
								}),

							/// SMS
							deliverySms: Yup.string()
								.nullable()
								.notRequired()
								.test('validate-sms', 'Invalid phone number', function (value) {
									if (!value) {
										return true
									}
									if (value && value.length > 0) {
										if (/^\+[1-9]\d{1,14}$/.test(value)) {
											return true
										} else {
											return false
										}
									} else {
										return true
									}
								}),

							confirmDeliverySms: Yup.string()
								.nullable()
								.notRequired()
								.test({
									name: 'deliverySms-confirmSms-match',
									message: 'Numbers must match',
									test: function (value) {
										const deliverySms = this.parent.deliverySms
										if (!deliverySms || value === deliverySms) {
											return true
										} else {
											return false
										}
									},
								}),

							id: Yup.string().nullable().default(null),
						})
						.required()
				)
				.test('at-least-one', 'At least one recipient is required', async function (values) {
					if (!values || values.length === 0) {
						// if the array is null or empty, return false to fail the validation
						return false
					}
					const fieldPaths = [
						...new Set(
							values.map((r) => Object.keys(r).filter((k) => /deliveryEmail|deliverySms/.test(k)))
						),
					]
					const atLeastOneFieldArrayFilled = values.some(function (v) {
						// remove id from the object
						var obj = { ...v }
						delete obj['id']
						// check if any field of the current object is not null, undefined, or an empty string
						return Object.values(obj).some(function (fieldValue) {
							return fieldValue !== null && fieldValue !== undefined && fieldValue !== ''
						})
					})
					if (atLeastOneFieldArrayFilled) {
						return true
					} else {
						const errors = fieldPaths.map((path, i) => {
							return this.createError({
								type: 'required',
								message: 'Please provide at least one entry',
								path: `recipients[${i}].${path}`,
							})
						})
						return new Yup.ValidationError(errors)
					}
				}),
			autoNotifyContributors: Yup.boolean().notRequired(),
			deliveryMessage: Yup.string().max(maxChars).required('You need to provide a message.'),
			deliveryDate: Yup.string().notRequired().nullable(),
		})
		.test({
			name: 'email_or_sms_validation',
			test: async function (value) {
				const keys = ['viaEmail', 'viaSms']
				const errors = keys
					.map((key) => {
						if (value[key] === false) {
							return this.createError({
								type: 'required',
								message: 'Please select mobile number or email',
								path: key,
							})
						}
						return null // everything is fine
					})
					.filter(Boolean)
				if (errors.length <= 1) {
					return true
				} else {
					return new Yup.ValidationError(errors)
				}
			},
		})

export default schemaRecipientDelivery
