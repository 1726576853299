import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { chakra, Button, HStack, useDisclosure, Stack, Divider, Spinner, Box } from '@chakra-ui/react'
import FieldArray from '../../../../../molecules/controls/FieldArray'
import { deleteRecipientAndReloadEvent, updateEvent, updateRecipient } from '../../../../../../../api/app/events'
import MessageTextarea from '../../../../../molecules/controls/MessageTextarea'
import {
	AccessTimeRounded,
	EmailOutboundRounded,
	InfoRounded,
	LocalPhoneRounded,
	MobilePhoneOutboundRounded,
	SendRounded,
} from '../../../../../atoms/Icon'
import ModalNotifyContributorsAuto from '../../../../../organisms/ModalNotifyContributorsAuto'
import { useComputedData } from '../../../../../../hooks/useComputedData'
import { useMedia } from '../../../../../../hooks/useMedia'
import ModalSetDeliveryDate from '../../../../../organisms/ModalSetDeliveryDate'
import Checkbox from '../../../../../molecules/controls/Checkbox'
import useDefaultDeliveryMessage from '../../../../../../hooks/useDefaultDeliveryMessage'

const FormDelivery = ({ maxChars, event, recipients, deliverNowModal, setEditMode }) => {
	const dispatch = useDispatch()

	const autoNotifyContributorsModal = useDisclosure()
	const setDeliveryDateModal = useDisclosure()

	const {
		handleSubmit,
		control,
		formState,
		formState: { errors, isSubmitting },
		watch,
		setValue,
		trigger,
	} = useFormContext()

	const { fields, append, remove, insert, update } = useFieldArray({
		control,
		name: 'recipients',
		keyName: 'key',
	})

	/** Watch values from the form */
	const values = watch()
	const media = useMedia()
	const { contributorsLength } = useComputedData({ media: media })
	const deliveryMessage = useDefaultDeliveryMessage()

	/**
	 * Set default <delivery message>  and notification checkbox
	 * */
	useEffect(() => {
		// register({name: 'deliveryDate', type:'custom'})
		if (event.id) {
			setValue('deliveryMessage', deliveryMessage)
			setValue('autoNotifyContributors', event.autoNotifyContributors || false)
		}
	}, [event, deliveryMessage])

	/**
	 * Set default recipients for the form
	 * */
	useEffect(() => {
		if (recipients && recipients.length > 0) {
			setValue('recipients', recipients)
		}
	}, [recipients])

	/**
	 * Method to delete a recipient provided to the FieldArray
	 */
	const deleteRecipient = (index) => {
		// Check if this recipient was existing initially or if we created a new one
		let currentRecipient = values.recipients[index]

		let didExistBefore = recipients.findIndex((el) => el.id === currentRecipient.id)

		/** Remove recipient from current the remote if it was already registered */
		if (currentRecipient && didExistBefore != -1) {
			dispatch(deleteRecipientAndReloadEvent(currentRecipient.id))
		}
		/** Remove recipient from the current field array */
		remove(index)
	}

	const onSubmitV2 = async (data) => {
		// console.log('DATA sub', data)
		// let isInvalid = -1
		// let itemsWithEmail = []
		// if (data.viaEmail) {
		// 	// We want to check that we have at least one recipient with an email
		// 	data.recipients.forEach((el, i) => {
		// 		if (el.deliveryEmail != '') itemsWithEmail.push(el)
		// 	})

		// 	// If we don't have any recipient with an email
		// 	if (itemsWithEmail.length == 0) {
		// 		// Trigger error?
		// 	} else {
		// 		// Otherwise, let's prepare the verification promise, as we know we have at least one field with an email
		// 		let checks = []

		// 		itemsWithEmail.forEach((el, i) => checks.push(verifyEmail(el.deliveryEmail)))

		// 		const emailChecks = await Promise.all(checks)

		// 		// Ensure our response is the same size as the initial input,
		// 		// Provide errors for fields that didn't pass verification
		// 		if (emailChecks && emailChecks.length == itemsWithEmail.length) {
		// 			emailChecks.forEach((res, i) => {
		// 				if (!res.isValid) {
		// 					setError(`recipients[${i}].deliveryEmail`, {
		// 						type: 'error',
		// 						message: 'Invalid email, please check your entry.',
		// 					})
		// 				}
		// 			})
		// 		}

		// 		isInvalid = emailChecks.findIndex((el, i) => el.isValid == false)
		// 	}
		// }

		/** Build data object */
		let putData = {
			id: event.id,
			...data,
		}
		/** Update event data with server */
		dispatch(updateEvent(putData, true))

		/** Loop through recipients value and update recipients on the server */
		data.recipients.forEach((r) => {
			dispatch(updateRecipient({ eventId: event.id, ...r }))
		})

		/** Launch the confirmation modal to notify recipient(s) if no delivery date provided */
		!data.deliveryDate && !event.deliveryDate && deliverNowModal.onOpen()

		/** Change view to status vs form */
		setEditMode(false)
	}

	const onSubmit = (data) => {
		if (data) {
			/** Build data object */
			let putData = {
				id: event.id,
				...data,
			}
			/** Update event data with server */
			dispatch(updateEvent(putData, true))

			/** Loop through recipients value and update recipients on the server */
			data?.recipients?.length > 0 &&
				data?.recipients?.forEach((r) => {
					dispatch(updateRecipient({ eventId: event.id, ...r }))
				})

			/** Launch the confirmation modal to notify recipient(s) if no delivery date provided */
			!data.deliveryDate && !event.deliveryDate && deliverNowModal.onOpen()
		}
		/** Change view to status vs form */
		setEditMode(false)
	}

	return (
		<>
			<chakra.form onSubmit={handleSubmit(onSubmitV2)} w="full">
				<MessageTextarea
					control={control}
					errors={errors}
					name="deliveryMessage"
					label="Gift Note"
					defaultValue={deliveryMessage}
					maxChars={maxChars}
					mb="1rem"
					textAreaProps={{ minH: ['80px'] }}
				/>

				<Box mb="1rem" maxW="fit-content">
					<Checkbox
						control={control}
						errors={errors}
						id={`viaEmail`}
						name={`viaEmail`}
						description={<chakra.strong color="gray.900">To Email</chakra.strong>}
						direction="row"
						justifyContent="start"
					/>
				</Box>

				<Box mb={values?.viaSms ? '2rem' : '1rem'} maxW="fit-content">
					<Checkbox
						control={control}
						errors={errors}
						id={`viaSms`}
						name={`viaSms`}
						description={<chakra.strong color="gray.900">To Mobile Number</chakra.strong>}
						direction="row"
						justifyContent="start"
					/>
				</Box>

				<FieldArray
					control={control}
					errors={errors}
					name="recipients"
					remove={deleteRecipient}
					insert={insert}
					append={append}
					update={update}
					allowMultiple={false}
					collectEmail={values?.viaEmail}
					confirmEmail={values?.viaEmail}
					collectSms={values?.viaSms}
					confirmSms={values?.viaSms}
					hideNames={true}
					values={values}
					data={fields}
					// onChange={()=>trigger()}
				/>

				<Divider mb="1rem" />

				{contributorsLength > 0 && (
					<HStack justifyContent="start" w="full">
						<Checkbox
							width="auto"
							control={control}
							errors={errors}
							name="autoNotifyContributors"
							description={
								<chakra.strong color="gray.900">
									Notify Participant{contributorsLength > 1 && `s`}?
								</chakra.strong>
							}
						/>
						<InfoRounded
							ml="0.25rem"
							cursor="pointer"
							width=".875rem"
							height=".875rem"
							mt="-0.125rem"
							color="link"
							d="inline"
							onClick={() => autoNotifyContributorsModal.onToggle()}
						/>
					</HStack>
				)}

				<Stack justifyContent="center" direction={['column', 'row']} spacing="1rem" mt="2rem" mb="1rem">
					{!event.deliveryDate ? (
						<Button
							variant="outline"
							size="sm"
							minW="120px"
							w="full"
							onClick={async () => {
								const result = await trigger()
								// if form validates allow schedule form.
								if (result) setDeliveryDateModal.onOpen()
							}}>
							Schedule Delivery
							<AccessTimeRounded ms=".5rem" />
						</Button>
					) : (
						<Button
							size="sm"
							minW="120px"
							w="full"
							variant="cancel"
							onClick={() => {
								setEditMode(false)
							}}>
							Cancel
						</Button>
					)}

					<Button size="sm" minW="166px" w="full" variant="solid" type="submit">
						{!event.deliveryDate ? 'Deliver Now' : 'Save'}
						{isSubmitting ? <Spinner color="white" ms=".5rem" /> : <SendRounded ms=".5rem" />}
					</Button>
				</Stack>
			</chakra.form>

			<ModalNotifyContributorsAuto
				isOpen={autoNotifyContributorsModal.isOpen}
				onClose={autoNotifyContributorsModal.onClose}
			/>

			<ModalSetDeliveryDate
				onSubmit={onSubmit}
				formData={values}
				event={event}
				isOpen={setDeliveryDateModal.isOpen}
				onClose={setDeliveryDateModal.onClose}
			/>
		</>
	)
}

export default FormDelivery
