import React from 'react'
import {
	Link,
	Modal,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
} from '@chakra-ui/react'
import useImageGenerator from '../../../hooks/useImageGenerator'
import { useEventHandlersV2 } from '../../../hooks/useEventHandlers'
import { useParams } from 'react-router-dom'
import { LaunchRounded } from '../../atoms/Icon'

const ModalNotifyContributorsAuto = ({ onClose, isOpen }) => {
	const { uuid } = useParams()
	const handlers = useEventHandlersV2(uuid)
	const img = useImageGenerator({
		path: '/assets/images/icons/notifications/icon-alert',
		ext: 'png',
	})
	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="md">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image w="40px" src={img.src} srcSet={img.srcset} alt="Notify Contributors" />
						<Heading size="lg" variant="hero">
							Notify Participants
						</Heading>
						<Text>
							Once the recipient watches their video, an email will be sent to all participants, and the
							event will be made available for them to watch.
						</Text>
						<Link href={handlers.invitation_list} target="_blank">
							See Participant List <LaunchRounded w="1.25rem" />
						</Link>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button variant="outline" onClick={onClose}>
							Okay, Thanks
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ModalNotifyContributorsAuto
